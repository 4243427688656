<template>
  <v-container>
    <v-row align="center">
      <v-col
        cols="5"
        class="ml-0 pl-0"
      >
        <ConfigurationSearch
          v-model="searchText"
          @@search="searchSets"
        />
      </v-col>
      <v-col
        cols="2"
        align="right"
      >
        <v-btn
          class="newOrg"
          :disabled="!selectedOrganization"
          elevation="0"
          @click="isNewSetOpen = true"
        >
          <v-icon left>
            {{ $t('sets.newSetIcon') }}
          </v-icon>
          {{ $t('sets.newSet') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="selectedOrganization"
        cols="3"
        align="right"
        style="text-align: left"
      >
        <span
          v-show="organizationSpeditors(selectedOrganization.id)"
          class="confCouriers"
        >
          {{ $t('sets.orgCouriers') }}
        </span>
        <div
          v-for="sped in organizationSpeditors(selectedOrganization.id)"
          id="img"
          :key="sped"
          :class="selectedSpeditor === sped ? ' imgFocus' : 'img'"
          tabindex="0"
          :style="'background-image: url(' + logo(sped) + ');'"
          @click="selectSpeditor(sped)"
        >
          <div class="number">
            <h4>
              {{ organizationSet(selectedOrganization.id, sped).length }}
            </h4>
          </div>
        </div>
      </v-col>
      <v-col
        v-if="selectedSpeditor"
        cols="7"
      >
        <v-list class="pa-2">
          <div class="listTitle">
            {{ `${$t('sets.setName')} ${$t(`speditors.${selectedSpeditor.toLowerCase()}`)}:` }}
          </div>

          <v-list-item class="pa-0">
            <v-list-item-content>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th style="height: 0px" />
                      <th
                        class="text-right"
                        style="height: 0px; width: 8%"
                      />
                      <th
                        class="text-right"
                        style="height: 0px; width: 8%"
                      />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="set in organizationSet(selectedOrganization.id, selectedSpeditor)"
                      :key="set.id"
                      @mouseover="onSetHover(set)"
                      @mouseleave="onSetHover()"
                    >
                      <td>{{ set.configurationName }}</td>

                      <td>
                        <div v-show="hoveredSet === set">
                          <v-btn
                            icon
                            @click="
                              editConfigurationSet(set.tenantId, set.courier, set.id, set.clientId)
                            "
                          >
                            <v-icon color="text">
                              {{ $t('sets.editIcon') }}
                            </v-icon>
                          </v-btn>
                        </div>
                      </td>
                      <td>
                        <div v-show="hoveredSet === set">
                          <v-btn
                            icon
                            @click="openDeleteDialog(set)"
                          >
                            <v-icon color="danger">
                              {{ $t('courierSet.deleteIcon') }}
                            </v-icon>
                          </v-btn>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col
        v-else-if="!selectedOrganization"
        offset="3"
        cols="7"
      >
        <v-card elevation="0">
          <v-card-title>
            Wybierz organizację dla której chcesz wyświetlić sposoby wysłki
          </v-card-title>
        </v-card>
      </v-col>
      <v-col
        v-else-if="organizationSpeditors(selectedOrganization.id)"
        cols="7"
      >
        <v-card elevation="0">
          <v-card-title>
            Wybierz kuriera dla którego chcesz wyświetlić sposoby wysyłki
          </v-card-title>
        </v-card>
      </v-col>
      <v-col
        v-else
        cols="7"
      >
        <v-card elevation="0">
          <v-card-title>
            {{ $t('sets.noData') }}
          </v-card-title>
          <v-card-text>
            {{ $t('sets.useNewSet') }}
          </v-card-text>
        </v-card>
      </v-col>
      <SelectOrganization
        v-if="isOpen"
        v-model="selectedOrganization"
        :organizations="organizations"
        :is-open="isOpen"
        @close="isOpen = false"
        @@next="selectOrganization"
      />
      <SelectSpeditor
        v-if="isNewSetOpen"
        v-model="hoveredSet"
        :organization="selectedOrganization"
        :is-open="isNewSetOpen"
        :couriers="organizationSpeditors(selectedOrganization.id)"
        @close="isNewSetOpen = false"
        @@createNewSet="createNewSet"
      />
      <DeleteSetDialog
        :is-open="isDeleteOpen"
        @close="isDeleteOpen = false"
        @delete="deleteSet(selectedSet.id)"
      />
    </v-row>
  </v-container>
</template>

<script>
import DeleteSetDialog from '@/components/dialogs/DeleteSetDialog';
import ConfigurationSearch from '@/views/ConfigurationSearch';
import policyServerApiService from '@/services/policyServer/policy-server-api';
import { roles } from '@/constants/Roles';
import { mapGetters } from 'vuex';
import configurationApiService from '@/services/spedition/configuration-api';
import SelectSpeditor from '@/components/dialogs/SelectSpeditor';
import { speditors } from '@/constants/Speditors';
import SelectOrganization from '@/components/dialogs/SelectOrganization';

export default {
  components: {
    ConfigurationSearch,
    SelectOrganization,
    SelectSpeditor,
    DeleteSetDialog,
  },
  data: () => ({
    searchText: null,
    organizations: [],
    configurationSets: [],
    hoveredSet: null,
    activeServices: null,
    users: [],
    roles,
    speditors,
    isNewSetOpen: false,
    isOpen: false,
    selectedSpeditor: null,
    isDeleteOpen: false,
  }),
  computed: {
    ...mapGetters(['oidcStore/oidcUser']),
    ...mapGetters('spedition', ['getOrganization']),
    userRole() {
      if (this['oidcStore/oidcUser']) {
        const user = this['oidcStore/oidcUser'];
        return user.roles?.find((x) => roles.ALL.some((y) => y === x));
      }
      return null;
    },
    selectedOrganization() {
      return this.getOrganization();
    },
  },
  async beforeMount() {
    this.$router.push('/organizationConfigurationSets');

    await this.getOrganizations();
    await this.getConfigurationSets();
    await this.getUsers();

    if (this.organizationSpeditors(this.selectedOrganization.id)) {
      this.selectedSpeditor = this.organizationSpeditors(this.selectedOrganization.id);
      this.selectedSpeditor = this.selectedSpeditor?.[0];
    }
  },
  methods: {
    openDeleteDialog(set) {
      this.selectedSet = set;
      this.isDeleteOpen = true;
    },
    async deleteSet(setId) {
      this.isDeleteOpen = false;
      const selectedSet = this.organizationSet(
        this.selectedOrganization.id,
        this.selectedSpeditor,
      ).find((x) => x.id === setId);
      const { courier } = selectedSet;
      const confSet = await configurationApiService.getConfigurationSet(
        courier,
        setId,
        this.usrId,
        this.selectedOrganization.id,
      );
      await configurationApiService.deleteConfigurationSet(
        courier,
        confSet.data,
        this.selectedOrganization.id,
      );
      await this.getConfigurationSets();
      this.selectedSet = null;
      if (!this.organizationSet(this.selectedOrganization.id, this.selectedSpeditor)) {
        this.selectedSpeditor = null;
      }
    },
    organizationSet(orgId, sped) {
      if (
        this.configurationSets?.filter((set) => set.tenantId === orgId && set.courier === sped)
          .length !== 0
      ) {
        return this.configurationSets?.filter(
          (set) =>
            set.tenantId === orgId &&
            set.courier === sped &&
            (set.configurationName.indexOf(this.searchText) > -1 ||
              set.id.indexOf(this.searchText) > -1 ||
              !this.searchText),
        );
      }
      return null;
    },
    organizationSpeditors(orgId) {
      const couriers = [];
      const sets = this.configurationSets?.filter(
        (set) =>
          set.tenantId === orgId &&
          (set.configurationName.indexOf(this.searchText) > -1 ||
            set.id.indexOf(this.searchText) > -1 ||
            !this.searchText),
      );
      if (sets.length !== 0) {
        Object.keys(sets).forEach((s) => couriers.push(sets[s].courier));
        return [...new Set(couriers)];
      }
      return null;
    },
    async getOrganizations() {
      try {
        this.organizations = await policyServerApiService.getOrganizations('', true);
      } catch (err) {
        const { response = '' } = err;
        const message = response?.data?.message || 'Błąd';
        this.$snotify.error(message);
        console.warn(err);
      }
    },
    async getConfigurationSets() {
      const configurationResponse = await configurationApiService.getAll();

      if (configurationResponse && configurationResponse.status === 200) {
        this.configurationSets = configurationResponse.data.items;
        this.configurationSets = this.configurationSets.map((x) => ({
          ...x,
          servicesLoading: {},
        }));
      }
    },
    async getUsers() {
      const usersResponse = await policyServerApiService.getUsers();
      if (usersResponse && usersResponse.status === 200) {
        this.users = usersResponse.data.items;
        this.users = this.users.map((x) => ({ ...x, servicesLoading: {} }));
      }
    },
    createNewSet({ speditorCode, service }) {
      this.isNewSetOpen = false;
      this.$router.push({
        name: `user-configuration-set-${speditorCode.toLowerCase()}-new`,
        params: {
          speditor: speditorCode,
          orgId: this.selectedOrganization.id,
          speditorService: service,
        },
      });
    },
    editConfigurationSet(organizationId, speditorCode, configurationId, userId) {
      this.$router.push({
        name: `user-configuration-set-${speditorCode.toLowerCase()}-edit`,
        params: {
          speditor: speditorCode,
          orgId: organizationId,
          confSetId: configurationId,
          usrId: userId,
        },
      });
    },
    searchSets({ searchText }) {
      this.searchText = searchText;
    },
    isServiceEnabled(serviceCode, org) {
      return org.services?.find((x) => x.serviceCode === serviceCode)?.isEnabled;
    },
    onSetHover(set) {
      this.hoveredSet = set;
    },
    selectOrganization({ organization }) {
      this.isOpen = false;
      this.$store.dispatch('setOrganization', { organization });
      if (this.organizationSpeditors(organization.id)) {
        this.selectedSpeditor = this.organizationSpeditors(organization.id);
        this.selectedSpeditor = this.selectedSpeditor?.[0];
      } else {
        this.selectedSpeditor = null;
      }
    },
    logo(speditor) {
      const spedLogo = this.speditors.find(
        (x) => x.code.toLowerCase() === speditor.toLowerCase(),
      ).logo;
      const images = require.context('@/assets/images/logo/speditors/', false, /\.png$/);
      return images(`./${spedLogo}`);
    },
    selectSpeditor(speditor) {
      this.selectedSpeditor = speditor;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/utilities/variables';
::v-deep .v-input__slot {
  align-items: center !important;
  justify-content: center !important;
}
.v-btn.newOrg {
  background-color: $primary-lighten1 !important;
  color: white;
  overflow: auto;
  height: 44px;
}
.v-btn.switchOrg {
  background-color: $white !important;
  border: 2px solid $primary-lighten1;
  color: $primary-lighten1;
  width: 237px !important;
  height: 48px !important;
  min-width: 237px !important;
}
.v-card__title {
  color: $text-lighten1;
}
.v-card__subtitle {
  margin: 0px 10px 0px 10px;
  padding: 5px 5px 5px 15px;
}
.v-text-field {
  margin: 0px 10px 0px 10px;
  padding: 0px 5px 0px 5px;
}
.userCol {
  display: flex;
  align-self: center;
  justify-content: center;
}
.parent {
  background-color: $priority_gray-lighten1;
  border-top: 1px solid $priority_gray;
  font-weight: bold;
}
.child:not(:first-child) {
  border-top: 1px solid $priority_gray;
  font-weight: bold;
}
.v-list-item {
  background-color: $white;
  font-weight: normal;
}
.listTitle {
  font-weight: bold;
  color: $text-lighten1;
  padding: 10px 0px 10px 12px;
}
.number {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: $primary-lighten4;
  color: $primary-lighten1;
  border: solid $primary-lighten1 2px;
  width: 32px;
  height: 24px;
  font-size: 12px;
}
.confCouriers {
  font-size: 16px;
  color: $text-lighten1;
  margin-bottom: 2px;
}
.number {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: $primary-lighten4;
  color: $primary-lighten1;
  border: solid $primary-lighten1 2px;
  width: 32px;
  height: 24px;
  font-size: 12px;
}
.imgFocus {
  border: 3px solid;
  border-color: $primary-lighten1;
  filter: opacity(100%);
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  background-color: $white;
  height: 128px;
  width: 253px;
  margin-bottom: 10px;
  padding: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 236.44px 112px;
  position: relative;
}
.img:hover {
  border: 3px solid;
  border-color: $primary-lighten1;
  filter: opacity(100%);
}
.img {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  border: 3px solid;
  border-color: $white;
  background-color: $white;
  height: 128px;
  width: 253px;
  margin-bottom: 10px;
  padding: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 236.44px 112px;
  position: relative;
  filter: opacity(50%);
}
</style>
